<template>
  <!--
    Placeholder-image (scalable svg) to be used when the real image is not available.
  -->
  <img
    v-if="image"
    loading="lazy"
    :src="image"
    class="alt-pointer alt-image-in-grid"
    @click="buttonClick"
  >
  <ImagePlaceholderSvg
    v-else
    @buttonClick="buttonClick"
  />
</template>
  
<script>
export default {
  name: 'GridImage',
  components: {
    ImagePlaceholderSvg: () => import('@/components/Base/ImagePlaceholderSvg.vue')
  },
  props: {
    token: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      image: null,
    }
  },
  created () {
    this.getPreviewImage();
  },
  methods: {
    buttonClick (event) {
      this.$emit('buttonClick', event);
    },
    getPreviewImage () {
      this.axios.get(`/PhotoTrap/GetFile?fileToken=${ this.token }`)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          if (response.data.base64Data == null) {
            return;
          }
          this.image = 'data:image/jpg;base64,' + response.data.base64Data;
        });
    },
  }
}
</script>

<style scoped>

</style>
